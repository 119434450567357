import { graphql } from "gatsby";
import SEO from "@components/seo";
import React from "react";
import { css } from "@emotion/react";
import { Div, Container } from "@components/Markup";
import PublicationThumbnail from "@components/PublicationThumbnail";
import Pagination from "@components/Pagination";
import SliderHero from "@components/SliderHero";

const POSTS_PER_PAGE = 60;

const Publications = ({ data }) => {
  const posts = data.posts.edges
    .map(edge => edge.node)
    .slice(0, POSTS_PER_PAGE);

  const featuredImages = data.page?.featuredImages;
  const slogan = data.page.slogan;
  const seoImage = featuredImages ? featuredImages[0].fluid.src : null;

  const seoTitle = `Hermann & Valentiny und Partner Architekten | Publikationen`;
  const seoDescription = slogan;

  return (
    <>
      <SEO title={seoTitle} description={seoDescription} image={seoImage} />
      <SliderHero
        items={featuredImages}
        pageTitle="Publikationen"
        pageSlogan={slogan}
      />
      <Container>
        <Div
          flex
          css={css`
            flex-wrap: wrap;

            @media (min-width: 768px) {
              margin: 0px -16px;
            }
          `}
        >
          {posts.map((post, index) => {
            return (
              <PublicationThumbnail
                key={`post--${index}`}
                id={`post--${index}`}
                {...post}
              />
            );
          })}
        </Div>
        {posts.length > POSTS_PER_PAGE && (
          <Pagination
            currentPage={1}
            pageCount={Math.ceil(posts.length / POSTS_PER_PAGE)}
            baseUrl={`/publikationen`}
          />
        )}
      </Container>
    </>
  );
};

export default Publications;

export const query = graphql`
  query PublicationsQuery {
    page: datoCmsPublikationenseite {
      slogan
      featuredImages {
        fluid {
          src
          srcSet
          aspectRatio
        }
      }
    }
    posts: allDatoCmsPublikation {
      edges {
        node {
          id
          title
          slug
          featuredImage {
            fluid {
              src
              srcSet
              aspectRatio
            }
          }
        }
      }
    }
  }
`;
